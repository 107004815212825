.arrow-small-purple {
    width: 38px;
    height: 27px;
    display: inline-block;
    background-image: url("./../images/arrow-small-purple.png");
}

.img-popup {
    display: block;
    position: relative;

    .btn-popup {
        left: 0;
        bottom: 0;
        color: @green;
        border: none;
        font-size: 19px;
        border-radius: 0;
        padding: 6px 12px;
        position: absolute !important;
        background-color: rgba(255, 255, 255, 0.8);

        &:focus {
            outline: none;
        }
    }
}
