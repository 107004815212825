#CipeMotionMediaPickerModal {
    .modal-body {
        padding: 0 !important;
        transition: opacity .25s ease-in-out;
        -moz-transition: opacity .25s ease-in-out;
        -webkit-transition: opacity .25s ease-in-out;
    }

    &.modal {
        .tab-content-wrapper {
            overflow: auto;
        }

        .tab-content {
            overflow: hidden;
        }

        .media-info, .tab-content {
            min-height: 400px;
        }

        .media-info {
            overflow: scroll;
        }

        .dropzone {
            border: 2px dashed #d1d7db;
            border-radius: 3px;

            .dz-message {
                width: 100%;
                text-align: center;
            }
        }

        .mediaLibrary-images {
            .medialibrary-image {
                width: 100%;
                height: 125px;
                display: block;
                border: 1px solid #d3d3d3;
                background: #fff center / cover no-repeat;
                border-radius: 0;

                .fa {
                    font-size: 6em;
                    margin-top: 22px;
                }
            }

            .medialibrary-title {
                background-color: #fff;
                font-size: 10px;
                padding: 5px 5px;
                height: 40px;
                border: 1px solid #d3d3d3;
                border-bottom-color: #c4c4c4;
                border-top: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 16px;
            }

            [data-medialibrary] {
                cursor: pointer;
                margin-bottom: 10px;
                box-shadow: 3px 3px 8px -2px rgba(0, 0, 0, 0.04);

                &:hover,
                &.img-selected {
                    .medialibrary-image {
                        padding: 0;
                        transition: none;
                        border-color: rgba(0, 116, 217, .3);
                        border-bottom-color: #d3d3d3;
                        box-shadow: 0 0 9px 11px rgba(0, 116, 217, 0.2) inset;
                    }
                }

                &.img-selected {
                    &:after {
                        content: "\f00c";
                        color: #fff;
                        background-color: lightblue;
                        position: absolute;
                        font-family: FontAwesome;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 1;
                        font-size-adjust: none;
                        font-stretch: normal;
                        font-feature-settings: normal;
                        font-language-override: normal;
                        font-kerning: auto;
                        font-synthesis: weight style;
                        font-variant: normal;
                        font-size: inherit;
                        text-rendering: auto;
                        -moz-osx-font-smoothing: grayscale;
                        left: 15px;
                        top: 15px;
                        font-size: 18px;
                        padding: 2px 3px;
                        border: 1px solid white;

                        left: auto;
                        right: 20px;
                        top: 6px;
                    }
                }

                @media (max-width: 767px) {
                    &.img-selected {
                        &:after {
                            left: auto;
                            right: 20px;
                            top: 6px;
                        }
                    }
                }
            }
        }
    }
}
