.border-grey {
    border-color: @grey;
}
.border-greylight {
    border-color: @greylight-border
}

.border-white {
    border-color: @white;
}

.bg-main {
    background-color: @main-bg;
}
.bg-success {
    background-color: @success-bg;
}
.bg-white {
    background-color: @white;
}
.bg-grey {
    background-color: @grey-bg;
}
.bg-green {
    background-color: @green-bg;
}

.text-main {
    color: @main;
}
.text-white {
    color: @white;
}
.text-grey {
    color: @grey;
}
.bg-greylight {
    background-color: @greylight-bg;
}
.text-greylight {
    color: @greylight-bg;
}

.text-purple {
    color: @purple;
}
.bg-purple {
    background-color: @greylight-bg;
}
.text-green {
    color: @green;
}

.text-black {
    color: @black;
}


.btn-facebook {
    color: @white !important;
    background-color: #3b5998 !important;
}
.text-facebook {
    color: #3b5998 !important;
}
.btn-twitter {
    color: @white !important;
    background-color: #00aced !important;
}
.text-twitter {
    color: #00aced !important;
}
.btn-linkedin {
    color: @white !important;
    background-color: #007bb6 !important;
}
.btn-dribbble {
    color: @white !important;
    background-color: #ea4c89 !important;
}
.btn-googleplus {
    color: @white !important;
    background-color: #dd4b39 !important;
}
.text-googleplus {
    color: #dd4b39 !important;
}
.btn-instagram  {
    color: @white !important;
    background-color: #517fa4 !important;
}
.text-instagram  {
    color: #517fa4 !important;
}
.btn-pinterest  {
    color: @white !important;
    background-color: #cb2027 !important;
}
.text-pinterest  {
    color: #cb2027 !important;
}
.btn-dropbox  {
    color: @white !important;
    background-color: #007ee5 !important;
}
.btn-flickr  {
    color: @white !important;
    background-color: #ff0084 !important;
}
.btn-tumblr  {
    color: @white !important;
    background-color: #32506d !important;
}
.btn-skype {
    color: @white !important;
    background-color: #00aff0 !important;
}
.btn-youtube  {
    color: @white !important;
    background-color: #bb0000 !important;
}
.btn-github  {
    color: @white !important;
    background-color: #171515 !important;
}

