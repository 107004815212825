.review-sum {
    font-size: 14px;
    font-weight: 300;

    > span {
        color: @main;
        display: block;
        font-size: 28px;
        font-weight: 800;
        text-align: center;
        margin-bottom: 3px;
    }

    @media (max-width: 766px) {
        > span {
            text-align: left !important;
        }
    }
}

.stars-review {
    font-size: 34px;

    &[data-rate="1"] .star:nth-child(-n+1),
    &[data-rate="2"] .star:nth-child(-n+2),
    &[data-rate="3"] .star:nth-child(-n+3),
    &[data-rate="4"] .star:nth-child(-n+4),
    &[data-rate="5"] .star:nth-child(-n+5) {
        color: #ffd700;

        &:before {
            content: "\f005";
        }
    }

    .star {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &:before {
            content: "\f006";
        }
    }
}

.starrr {
    i.fa {
        color: #ffd700;
        cursor: pointer;
        font-size: 25px;
        margin-right: 4px;
    }
}

.listitem-company {
    padding: 12px 0;
    min-height: 178px;
    border: 1px solid @greylight-border;

    + .listitem-company {
        margin-top: 20px;
    }

    &.listitem-company-top-ad {
        border-width: 2px;
        border-color:#ffb57e;
        background-color: #FFF3DF;

        //min-height: 64px;

        //.company-title {
        //    display: inline;
        //    margin-top: 0;
        //}
        //
        //.company_current {
        //    display: inline;
        //}
        //
        //.company-address {
        //    margin-bottom: 0;
        //}
        //
        //.company_info-right {
        //    top: 6px;
        //    bottom: auto;
        //
        //    .btn {
        //        margin-top: 0;
        //    }
        //}
    }

    .bg-image {
        height: 100%;
    }

    .company-title {
        font-size: 19px;
        font-weight: 600;
        margin-top: 12px;
        margin-bottom: 0;
    }

    .company-address {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 14px;
    }

    .dl-horizontal {
        margin-bottom: 0;

        > dt, > dd {
            font-size: 14px;
            font-weight: 300;
            line-height: 27px;
        }

        > dt {
            width: auto;
        }

        > dd {
            margin-left: 25px;
        }
    }

    .company_certs {
        img {
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            padding: 0 10px;
            opacity: 0.4;
        }
    }

    .company_current {
        font-size: 14px;
        font-weight: 300;
    }

    .review-sum {
        margin-top: 11px;
        margin-right: 11px;
    }

    .company_info-right {
        right: 0;
        bottom: -6px;
        float: right;
        position: absolute;
        text-align: left;

        a {
            font-size: 14px;
            margin-top: 17px;
            margin-left: -9px;
            padding: 10px 14px 10px 22px;
        }

        .btn {
            margin-right: 10px;
        }
    }

    @media (max-width: 767px) {
        .dl-horizontal {
            > dd {
                margin-top: -27px;
            }
        }

        .company_info-right {
            right: 0;
            bottom: 0;
            float: none;
            margin-top: 15px;
            position: relative;
            text-align: center;

            a {
                margin-left: 0;
            }

            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }

            .btn {
                margin-right: 0;
            }
        }
    }
}

.company-search {
    .company-search-form {
        padding-bottom: 30px;
        border-right: 2px solid @greylight-border;

        .btn-company-search {
            font-size: 14px;
            font-weight: bold;
            line-height: 29px;
        }

        .collapse:not(.in) {
            display: block;
            height: auto !important;
        }
    }

    .btn-show-map {
        color: @grey;
        font-size: 14px;
        line-height: 37px;
        font-weight: normal;
    }

    @media (max-width: 766px) {
        .company-search-form {
            border-right: none;

            .collapse:not(.in) {
                display: none;
            }
        }
    }
}

.company-single {
    .main-image {
        height: 400px;
    }

    .review-sum > span {
        text-align: right;
    }

    .company-logo {
        font-size: 14px;

        .dl-horizontal {
            margin-bottom: 0;

            > dt, > dd {
                font-size: 14px;
                font-weight: 300;
                line-height: 27px;
            }

            > dt {
                width: auto;
            }

            > dd {
                margin-left: 25px;
            }
        }
    }

    .company-hours, .company-prices {
        .dl-horizontal {
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 0;
            line-height: 22px;

            > dt {
                width: 100px;
                text-align: left;
                margin-bottom: 4px;

                &:not(.font-bold) {
                    font-weight: 300;
                }
            }

            > dd {
                margin-left: 96px;
            }

            @media (max-width: 766px) {
                > dt:not(:first-of-type) {
                    margin-top: 9px;
                }

                > dd {
                    margin-top: -24px;
                }
            }
        }
    }
}

.company-hours, .company_current {
    &.company_current-open {
        color: @open;
        font-weight: 600 !important;
    }
}
