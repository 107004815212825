@import "fonts.less";
@import "base.less";
@import "fix-plugins.less";
@import "variables.less";
@import "coloring.less";
@import "misc.less";
@import "footer.less";
@import "images.less";
@import "mediapicker.less";
@import "company.less";
@import "pages.less";
@import "manager.less";
@import "responsive.less";

.font-thin {
    font-weight: 300;
}

.font-light {
    font-weight: 300;
}

.font-normal {
    font-weight: 300;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: bold;
}

.font-extrabold {
    font-weight: 800;
}

.fs-14 {
    font-size: 14px;
}

.fs-26 {
    font-size: 26px;
}

.fs-29 {
    font-size: 29px;
}

.underline-dotted {
    border-bottom: 1px dotted #777;
}



/* Needs to be oredered */
.msg-empty {
    color: @grey;
    padding: 30px;
    font-style: italic;
    text-align: center;

    > .fa {
        display: block;
        margin-bottom: 10px;
    }
}
.image-block {
    color: @white;
    display: block;
    font-size: 28px;
    min-width: 250px;
    min-height: 200px;
    font-weight: bold;
    text-align: center;
    position: relative;

    p, button {
        position: absolute;
        z-index: 10;
    }

    p {
        top: 39%;
        font-weight: 800;
        transform: translate(0, -50%);
    }

    button {
        top: 74%;
        padding: 7px 28px;
        transform: translate(-50%, -50%);
    }

    &:hover {
        color: @white;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;

        overflow: hidden;
        background: rgba(0,0,0,0.25);
    }
}


























.navbar.navbar-menu .navbar-nav > li > a.btn-menu-search {
    line-height: 20px;
    padding: 12px 13px;
}


.pointer {
    cursor: pointer;
}

[data-geo-location] {
    &.geo-location-not-supported {
        opacity: 0.4;
    }

    &.geo-location-loading {
        -webkit-animation: fa-spin 2s infinite linear;
        animation: fa-spin 2s infinite linear;

        &:before {
            content: "\f110";
        }
    }
}




[v-cloak], [ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.radio input[type=checkbox]:checked + label:after,
.radio input[type=radio]:checked + label:after,
.checkbox input[type=checkbox]:checked + label:after,
.checkbox input[type=radio]:checked + label:after {
    top: -4px;
    color: @main;
    font-size: 17px;
    content: "\f00c";
    font-family: FontAwesome;
}

.text-transform-normal {
    text-transform: none;
}

.alert-grey {
    color: @main;
    background-color: @greylight-bg;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: @text;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    background-color: @white;
    font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;

    &:not(.manager):not(.homepage) {
        padding-top: 88px;

        @media (max-width: 767px) {
            padding-top: 50px;
        }
    }
}


        //.row {
        //    > [class^="col"]:first-of-type {
        //        padding-left: 15px;
        //    }
        //
        //    > [class^="col"]:last-of-type {
        //        padding-right: 15px;
        //    }
        //}

iframe {
    border: none !important;
}

a {
    color: inherit;

    &:hover, &:focus {
        text-decoration: none;
    }
}

.help-block {
    a {
        font-weight: 600;
    }
}

sup {
    color: @danger;
}

button, .btn {
    font-weight: 600;

    &:not(.btn-border) {
        border-style: solid;
        border: 0;
    }
}

h1, h2, h3, h4, h5, h6 {
    color: @black;
    font-weight: 600;
}

//h1.text-white-styled,
//.h1.text-white-styled {
//    font-weight: 800;
//    letter-spacing: 1.5px;
//
//    > span {
//        color: @main;
//    }
//}

h3, .h3 {
    font-size: 19px;
    font-weight: 600;
}

h5, .h5 {
    font-weight: 500;
}

.text-opacity {
    opacity: 0.6;
}

@media (min-width: 767px) {
    //.action-items .row-eq-height .item-default {
    //    height: 100%;
    //}

    .row-eq-height {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

        > [class^="col-"] {
            > .panel {
                height: 100%;
                margin-bottom: 0;
            }
        }
    }
}
//
//.edited-field {
//    color: orange;
//}
//
//.tab-has-edits {
//    background-color: orange !important;
//}
//
//.side_div {
//    top: 400px;
//    right: -85px;
//    z-index: 10000;
//    position: fixed;
//    transform: rotate(-90deg);
//    -ms-transform: rotate(-90deg);
//    -webkit-transform: rotate(-90deg);
//
//    .btn {
//        border-radius: 2px;
//        border-bottom-left-radius: 0;
//        border-bottom-right-radius: 0;
//
//        &:hover, &:active, &:focus, &:active:focus {
//            color: @white;
//            background-color: @purple-bg;
//        }
//    }
//
//    @media (max-width: 767px) {
//        display: none;
//    }
//}

//.list-dotted {
//    list-style-type: none;
//
//    li:before {
//        content: "\f10c";
//        margin-left: -40px;
//        margin-right: 10px;
//        font-size: inherit;
//        text-rendering: auto;
//        display: inline-block;
//        -webkit-font-smoothing: antialiased;
//        font: normal normal normal 13px/1 FontAwesome;
//    }
//}

//.list-blocked {
//    list-style-type: none;
//
//    li:before {
//        color: #FAC029;
//        content: "\f04d";
//        margin-left: -29px;
//        margin-right: 7px;
//        font-size: inherit;
//        text-rendering: auto;
//        display: inline-block;
//        -webkit-font-smoothing: antialiased;
//        font: normal normal normal 9px/1 FontAwesome;
//
//        -ms-transform: rotate(45deg); /* IE 9 */
//        -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
//        transform: rotate(45deg);
//    }
//}
