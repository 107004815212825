/**
 *   DataTables Bootstrap
 **/
table.dataTable {
    width: 100% !important;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    font-family: 'FontAwesome';
}
table.dataTable thead .sorting:after {
    content: "\f0dc";
    /* sort */
}
table.dataTable thead .sorting_asc:after {
    content: "\f0de";
    /* sort-by-attributes */
}
table.dataTable thead .sorting_desc:after {
    content: "\f0dd";
    /* sort-by-attributes-alt */
}


/* Datetimepicer */
.bootstrap-datetimepicker-widget table td.disabled, .bootstrap-datetimepicker-widget table td.disabled:hover {
    color: @gray-light;
}

/* Summernote */
.note-editor.note-frame {
    border-radius: 0;
    border: 1px solid #C5C5C5;
}

.panel-heading.note-toolbar,
.note-editor.note-frame .note-statusbar {
    border-radius: 0;
}

.note-editable img, .panel-content img {
    display: block;
    max-width: 100%;
    height: auto;
}


.panel-body img {
    &[style*="float: right"],
    &[style*="float:right"] {
        margin-left: 10px;
    }

    &[style*="float: left"],
    &[style*="float:left"] {
        margin-right: 10px;
    }
}

.news-editor {
    .note-options {
        display: none;
    }
}

.fancybox-overlay {
    .fancybox-close {
        background-image: url(../images/fancybox_sprite_purple.png);
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5){

    .fancybox-overlay {
        .fancybox-close {
            background-image: url(../images/fancybox_sprite_purple@2x.png);
            background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
        }
    }
}

@media (min-width: @screen-xs-max) {
    .fancybox-overlay {
        .fancybox-close {
            top: 0;
            right: -41px;
        }
        .fancybox-skin {
            border-radius: 0;
            text-shadow: none;
        }

        .fancybox-prev {
            //left: -70px;

            span {
                color: white;
                font-size: 40px;
                line-height: 40px;
                visibility: visible;
                background: transparent;
                font-family: 'FontAwesome';

                &:after {
                    content: '\f053';
                }

            }
        }

        .fancybox-next {
            //right: -70px;

            span {
                color: white;
                font-size: 40px;
                line-height: 38px;
                visibility: visible;
                background: transparent;
                font-family: 'FontAwesome';

                &:after {
                    content: '\f054';
                }

            }
        }
    }
}

/* Selectize */
.has-error {
    .selectize-control .selectize-input {
        border-color: #a94442;
    }
}

.selectize-control {
    text-align: left;

    .description {
        display: block;
        font-size: 12px;
    }

    &.selectize_single {
        .selectize-input > div,
        .selectize-input > div.active {
            width: 100%;
            color: #333;
            background: transparent;
        }

        &.plugin-remove_button [data-value] .remove {
            color: #c2c2c2;
            font-size: 31px;
        }
    }

    &.input-lg {
        &.multi .selectize-input.has-items,
        .selectize-input {
            padding: 13px 15px;
        }
    }

    &.single {
        &:not(.selectize-hide-dropdown) {
            .selectize-input {
                &:after {
                    top: auto;
                    right: 17px;
                    border: none;
                    content: "\f078";
                    color: #c2c2c2;
                    position: absolute;
                    padding-right: 8px;
                    margin-top: -2px;
                    display: inline-block;
                    text-rendering: auto;
                    //-webkit-transition: all 0.15s ease-in-out;
                    //transition: all 0.15s ease-in-out;
                    -webkit-font-smoothing: antialiased;
                    font-family: FontAwesome;
                }

                &.dropdown-active {
                    &:after {
                        border: none;
                        //margin-top: -4px
                        content: "\f077";
                    }
                }
            }
        }

       &.selectize-hide-dropdown {
           .selectize-input {
               &:after {
                   display: none;
               }
           }
        }
    }

    .selectize-dropdown {
        text-align: left;

        .selectize-dropdown-content {
            border-radius: @border-radius;
        }
    }

    .selectize-input {
        font-weight: 400;
        border-color: @input-border;
        border-radius: @border-radius;

        &.dropdown-active {
            border-radius: @border-radius;
        }
    }
}

fieldset[disabled] {
    .form-control {
        &.selectize-control:not(.tags-min-height) {
            height: 34px;
        }
    }
}
