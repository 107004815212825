.col-sm-25 {
    min-height: 1px;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
}

@media(min-width: 550px) {
    .col-sm-25 {
        width: 20%;
        float: left;
    }
}

.col-md-25 {
    min-height: 1px;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
}

@media(min-width: 768px) {
    .col-md-25 {
        width: 20%;
        float: left;
    }
}

@media (max-width: 767px) {
    .sm-center-block {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.font-italic {
    font-style: italic;
}

.text-hint {
    color: @grey;
}
.normal-inline {
    display: inline-block;
}

/* Overides
------------------------------------
*/
.no-padding {
    padding: 0px !important;
}
.no-margin {
    margin: 0px !important;
}
.no-overflow {
    overflow: hidden !important;
}
.auto-overflow {
    overflow: auto;
}
.center-margin {
    margin-left: auto;
    margin-right: auto;
}
.inherit-size {
    width: inherit;
    height: inherit;
}
.inherit-height {
    height: inherit;
}
.image-responsive-height {
    width: 100%;
}
.image-responsive-width {
    height: 100%;
}
.overlayer {
    position: absolute;
    display: block;
    z-index: 21;
}
.overlayer.fullwidth {
    width: 100%;
}
.overlayer-wrapper {
    position: relative;
    display: block;
    z-index: 10;
}
.overlay-fixed {
    position: fixed !important;
    top: auto !important;
}

/***** *****/





.top-left {
    position: absolute !important;
    top: 0;
    left: 0;
}
.top-right {
    position: absolute !important;
    top: 1px;
    right: 0;
}
.bottom-left {
    position: absolute !important;
    bottom: 1px;
    left: 0;
}
.bottom-right {
    position: absolute !important;
    bottom: 0;
    right: 0;
}
.pull-bottom {
    position: absolute !important;
    bottom: 0;
}
.pull-up {
    position: absolute !important;
    top: 0;
}
.cursor {
    cursor: pointer;
}
.scroll-x-hidden {
    overflow-x: hidden !important;
}
/* Generic Padding Helpers
------------------------------------
*/
.p-r-0 {
    padding-right: 0 !important;
}
.p-l-0 {
    padding-left: 0 !important;
}
.p-t-5 {
    padding-top: 5px !important;
}
.p-r-5 {
    padding-right: 5px !important;
}
.p-l-5 {
    padding-left: 5px !important;
}
.p-b-5 {
    padding-bottom: 5px !important;
}
.padding-5 {
    padding: 5px !important;
}
.p-t-10 {
    padding-top: 10px !important;
}
.p-r-10 {
    padding-right: 10px !important;
}
.p-l-10 {
    padding-left: 10px !important;
}
.p-b-10 {
    padding-bottom: 10px !important;
}
.padding-10 {
    padding: 10px !important;
}
.p-t-15 {
    padding-top: 15px !important;
}
.p-r-15 {
    padding-right: 15px !important;
}
.p-l-15 {
    padding-left: 15px !important;
}
.p-b-15 {
    padding-bottom: 15px !important;
}
.padding-15 {
    padding: 15px !important;
}
.p-t-20 {
    padding-top: 20px !important;
}
.p-r-20 {
    padding-right: 20px !important;
}
.p-l-20 {
    padding-left: 20px !important;
}
.p-b-20 {
    padding-bottom: 20px !important;
}
.padding-20 {
    padding: 20px !important;
}
.p-t-25 {
    padding-top: 25px !important;
}
.p-r-25 {
    padding-right: 25px !important;
}
.p-l-25 {
    padding-left: 25px !important;
}
.p-b-25 {
    padding-bottom: 25px !important;
}
.padding-25 {
    padding: 25px !important;
}
.p-t-30 {
    padding-top: 30px !important;
}
.p-r-30 {
    padding-right: 30px !important;
}
.p-l-30 {
    padding-left: 30px !important;
}
.p-b-30 {
    padding-bottom: 30px !important;
}
.padding-30 {
    padding: 30px !important;
}
.p-t-35 {
    padding-top: 35px !important;
}
.p-r-35 {
    padding-right: 35px !important;
}
.p-l-35 {
    padding-left: 35px !important;
}
.p-b-35 {
    padding-bottom: 35px !important;
}
.padding-35 {
    padding: 35px !important;
}
.p-t-40 {
    padding-top: 40px !important;
}
.p-r-40 {
    padding-right: 40px !important;
}
.p-l-40 {
    padding-left: 40px !important;
}
.p-b-40 {
    padding-bottom: 40px !important;
}
.padding-40 {
    padding: 40px !important;
}
.p-t-45 {
    padding-top: 45px !important;
}
.p-r-45 {
    padding-right: 45px !important;
}
.p-l-45 {
    padding-left: 45px !important;
}
.p-b-45 {
    padding-bottom: 45px !important;
}
.padding-45 {
    padding: 45px !important;
}
.p-t-50 {
    padding-top: 50px !important;
}
.p-r-50 {
    padding-right: 50px !important;
}
.p-l-50 {
    padding-left: 50px !important;
}
.p-b-50 {
    padding-bottom: 50px !important;
}
.padding-50 {
    padding: 50px !important;
}
.p-t-55 {
    padding-top: 55px !important;
}
.p-r-55 {
    padding-right: 55px !important;
}
.p-l-55 {
    padding-left: 55px !important;
}
.p-b-55 {
    padding-bottom: 55px !important;
}
.padding-55 {
    padding: 55px !important;
}
.p-t-60 {
    padding-top: 60px !important;
}
.p-r-60 {
    padding-right: 60px !important;
}
.p-l-60 {
    padding-left: 60px !important;
}
.p-b-60 {
    padding-bottom: 60px !important;
}
.padding-60 {
    padding: 60px !important;
}
.p-t-65 {
    padding-top: 65px !important;
}
.p-r-65 {
    padding-right: 65px !important;
}
.p-l-65 {
    padding-left: 65px !important;
}
.p-b-65 {
    padding-bottom: 65px !important;
}
.padding-65 {
    padding: 65px !important;
}
.p-t-70 {
    padding-top: 70px !important;
}
.p-r-70 {
    padding-right: 70px !important;
}
.p-l-70 {
    padding-left: 70px !important;
}
.p-b-70 {
    padding-bottom: 70px !important;
}
.padding-70 {
    padding: 70px !important;
}
.p-t-75 {
    padding-top: 75px !important;
}
.p-r-75 {
    padding-right: 75px !important;
}
.p-l-75 {
    padding-left: 75px !important;
}
.p-b-75 {
    padding-bottom: 75px !important;
}
.padding-75 {
    padding: 75px !important;
}
.p-t-80 {
    padding-top: 80px !important;
}
.p-r-80 {
    padding-right: 80px !important;
}
.p-l-80 {
    padding-left: 80px !important;
}
.p-b-80 {
    padding-bottom: 80px !important;
}
.padding-80 {
    padding: 80px !important;
}
.p-t-85 {
    padding-top: 85px !important;
}
.p-r-85 {
    padding-right: 85px !important;
}
.p-l-85 {
    padding-left: 85px !important;
}
.p-b-85 {
    padding-bottom: 85px !important;
}
.padding-85 {
    padding: 85px !important;
}
.p-t-90 {
    padding-top: 90px !important;
}
.p-r-90 {
    padding-right: 90px !important;
}
.p-l-90 {
    padding-left: 90px !important;
}
.p-b-90 {
    padding-bottom: 90px !important;
}
.padding-90 {
    padding: 90px !important;
}
.p-t-95 {
    padding-top: 95px !important;
}
.p-r-95 {
    padding-right: 95px !important;
}
.p-l-95 {
    padding-left: 95px !important;
}
.p-b-95 {
    padding-bottom: 95px !important;
}
.padding-95 {
    padding: 95px !important;
}
.p-t-100 {
    padding-top: 100px !important;
}
.p-r-100 {
    padding-right: 100px !important;
}
.p-l-100 {
    padding-left: 100px !important;
}
.p-b-100 {
    padding-bottom: 100px !important;
}
.padding-100 {
    padding: 100px !important;
}
/* Generic Margin Helpers
------------------------------------
 */
.m-t-0 {
    margin-top: 0px;
}
.m-r-0 {
    margin-right: 0px;
}
.m-l-0 {
    margin-left: 0px;
}
.m-b-0 {
    margin-bottom: 0px;
}
.margin--5 {
    margin: -5px;
}
.m-t-5 {
    margin-top: 5px;
}
.m-r-5 {
    margin-right: 5px;
}
.m-l-5 {
    margin-left: 5px;
}
.m-b-5 {
    margin-bottom: 5px;
}
.m-t-10 {
    margin-top: 10px;
}
.m-r-10 {
    margin-right: 10px;
}
.m-l-10 {
    margin-left: 10px;
}
.m-b-10 {
    margin-bottom: 10px;
}
.m-t-15 {
    margin-top: 15px;
}
.m-r-15 {
    margin-right: 15px;
}
.m-l-15 {
    margin-left: 15px;
}
.m-b-15 {
    margin-bottom: 15px;
}
.m-t-20 {
    margin-top: 20px;
}
.m-r-20 {
    margin-right: 20px;
}
.m-l-20 {
    margin-left: 20px;
}
.m-b-20 {
    margin-bottom: 20px;
}
.m-t-25 {
    margin-top: 25px;
}
.m-r-25 {
    margin-right: 25px;
}
.m-l-25 {
    margin-left: 25px;
}
.m-b-25 {
    margin-bottom: 25px;
}
.m-t-30 {
    margin-top: 30px;
}
.m-r-30 {
    margin-right: 30px;
}
.m-l-30 {
    margin-left: 30px;
}
.m-b-30 {
    margin-bottom: 30px;
}
.m-t-35 {
    margin-top: 35px;
}
.m-r-35 {
    margin-right: 35px;
}
.m-l-35 {
    margin-left: 35px;
}
.m-b-35 {
    margin-bottom: 35px;
}
.m-t-40 {
    margin-top: 40px;
}
.m-r-40 {
    margin-right: 40px;
}
.m-l-40 {
    margin-left: 40px;
}
.m-b-40 {
    margin-bottom: 40px;
}
.m-t-45 {
    margin-top: 45px;
}
.m-r-45 {
    margin-right: 45px;
}
.m-l-45 {
    margin-left: 45px;
}
.m-b-45 {
    margin-bottom: 45px;
}
.m-t-50 {
    margin-top: 50px;
}
.m-r-50 {
    margin-right: 50px;
}
.m-l-50 {
    margin-left: 50px;
}
.m-b-50 {
    margin-bottom: 50px;
}
.m-t-55 {
    margin-top: 55px;
}
.m-r-55 {
    margin-right: 55px;
}
.m-l-55 {
    margin-left: 55px;
}
.m-b-55 {
    margin-bottom: 55px;
}
.m-t-60 {
    margin-top: 60px;
}
.m-r-60 {
    margin-right: 60px;
}
.m-l-60 {
    margin-left: 60px;
}
.m-b-60 {
    margin-bottom: 60px;
}
.m-t-65 {
    margin-top: 65px;
}
.m-r-65 {
    margin-right: 65px;
}
.m-l-65 {
    margin-left: 65px;
}
.m-b-65 {
    margin-bottom: 65px;
}
.m-t-70 {
    margin-top: 70px;
}
.m-r-70 {
    margin-right: 70px;
}
.m-l-70 {
    margin-left: 70px;
}
.m-b-70 {
    margin-bottom: 70px;
}
.m-t-75 {
    margin-top: 75px;
}
.m-r-75 {
    margin-right: 75px;
}
.m-l-75 {
    margin-left: 75px;
}
.m-b-75 {
    margin-bottom: 75px;
}
.m-t-80 {
    margin-top: 80px;
}
.m-r-80 {
    margin-right: 80px;
}
.m-l-80 {
    margin-left: 80px;
}
.m-b-80 {
    margin-bottom: 80px;
}
.m-t-85 {
    margin-top: 85px;
}
.m-r-85 {
    margin-right: 85px;
}
.m-l-85 {
    margin-left: 85px;
}
.m-b-85 {
    margin-bottom: 85px;
}
.m-t-90 {
    margin-top: 90px;
}
.m-r-90 {
    margin-right: 90px;
}
.m-l-90 {
    margin-left: 90px;
}
.m-b-90 {
    margin-bottom: 90px;
}
.m-t-95 {
    margin-top: 95px;
}
.m-r-95 {
    margin-right: 95px;
}
.m-l-95 {
    margin-left: 95px;
}
.m-b-95 {
    margin-bottom: 95px;
}
.m-t-100 {
    margin-top: 100px;
}
.m-r-100 {
    margin-right: 100px;
}
.m-l-100 {
    margin-left: 100px;
}
.m-b-100 {
    margin-bottom: 100px;
}
.full-height {
    height: 100% !important;
}
.full-width {
    width: 100%;
}
.hide {
    display: none;
}
.inline {
    display: inline-block !important;
}
.block {
    display: block;
}

.relative {
    position: relative;
}

/* Border Helpers
------------------------------------
*/
.b-a,
.b-r,
.b-l,
.b-t,
.b-b {
    border-style: solid;
    border-width: 0;
}
.b-r {
    border-right-width: 1px;
}
.b-l {
    border-left-width: 1px;
}
.b-t {
    border-top-width: 1px;
}
.b-b {
    border-bottom-width: 1px;
}
.b-a {
    border-width: 1px;
}
.b-dashed {
    border-style: dashed;
}
.b-thick {
    border-width: 2px;
}

/* Border Radius
------------------------------------
*/
.b-rad-sm {
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
}
.b-rad-md {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}
.b-rad-lg {
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
}
.no-border {
    border: none !important;
}

.visible-xlg {
    display: none;
}
.hidden-xlg {
    display: block;
}
.sm-gutter .row > [class^="col-"],
.sm-gutter .row > [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}
.sm-gutter .row {
    margin-left: -5px;
    margin-right: -5px;
}


.v-align-bottom {
    vertical-align: bottom;
}
.v-align-top {
    vertical-align: top;
}
.v-align-middle {
    vertical-align: middle;
}
/* vertical alignment styles */
.col-top {
    vertical-align: top;
}
.col-middle {
    vertical-align: middle;
}
.col-bottom {
    vertical-align: bottom;
}

/* columns of same height styles
------------------------------------
*/
.container-xs-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}
.row-xs-height {
    display: table-row;
}
.col-xs-height {
    display: table-cell;
    float: none;
}
@media (min-width: 768px) {
    .container-sm-height {
        display: table;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
    }
    .row-sm-height {
        display: table-row;
    }
    .col-sm-height {
        display: table-cell !important;
        float: none !important;
    }
}
@media (min-width: 992px) {
    .container-md-height {
        display: table;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
    }
    .row-md-height {
        display: table-row;
    }
    .col-md-height {
        display: table-cell !important;
        float: none !important;
    }
}
@media (min-width: 1200px) {
    .container-lg-height {
        display: table;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
    }
    .row-lg-height {
        display: table-row;
    }
    .col-lg-height {
        display: table-cell !important;
        float: none !important;
    }
}

/*** Large screens ***/
@media (min-width: 1824px) {
    .visible-xlg {
        display: block !important;
    }
    .hidden-xlg {
        display: none !important;
    }
    .container-fluid.container-fixed-lg {
        width: 1700px;
        margin-right: auto;
        margin-left: auto;
    }
    .visible-xlg {
        display: block !important;
    }
    .hidden-xlg {
        display: none !important;
    }
    .col-xlg-1,
    .col-xlg-2,
    .col-xlg-3,
    .col-xlg-4,
    .col-xlg-5,
    .col-xlg-6,
    .col-xlg-7,
    .col-xlg-8,
    .col-xlg-9,
    .col-xlg-10,
    .col-xlg-11 {
        float: left;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
    }
    .col-xlg-12 {
        float: left;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        width: 100%;
    }
    .col-xlg-11 {
        width: 91.6667%;
    }
    .col-xlg-10 {
        width: 83.3333%;
    }
    .col-xlg-9 {
        width: 75%;
    }
    .col-xlg-8 {
        width: 66.6667%;
    }
    .col-xlg-7 {
        width: 58.3333%;
    }
    .col-xlg-6 {
        width: 50%;
    }
    .col-xlg-5 {
        width: 41.6667%;
    }
    .col-xlg-4 {
        width: 33.3333%;
    }
    .col-xlg-3 {
        width: 25%;
    }
    .col-xlg-2 {
        width: 16.6667%;
    }
    .col-xlg-1 {
        width: 8.33333%;
    }
    .col-xlg-pull-12 {
        right: 100%;
    }
    .col-xlg-pull-11 {
        right: 91.6667%;
    }
    .col-xlg-pull-10 {
        right: 83.3333%;
    }
    .col-xlg-pull-9 {
        right: 75%;
    }
    .col-xlg-pull-8 {
        right: 66.6667%;
    }
    .col-xlg-pull-7 {
        right: 58.3333%;
    }
    .col-xlg-pull-6 {
        right: 50%;
    }
    .col-xlg-pull-5 {
        right: 41.6667%;
    }
    .col-xlg-pull-4 {
        right: 33.3333%;
    }
    .col-xlg-pull-3 {
        right: 25%;
    }
    .col-xlg-pull-2 {
        right: 16.6667%;
    }
    .col-xlg-pull-1 {
        right: 8.33333%;
    }
    .col-xlg-pull-0 {
        right: 0;
    }
    .col-xlg-push-12 {
        left: 100%;
    }
    .col-xlg-push-11 {
        left: 91.6667%;
    }
    .col-xlg-push-10 {
        left: 83.3333%;
    }
    .col-xlg-push-9 {
        left: 75%;
    }
    .col-xlg-push-8 {
        left: 66.6667%;
    }
    .col-xlg-push-7 {
        left: 58.3333%;
    }
    .col-xlg-push-6 {
        left: 50%;
    }
    .col-xlg-push-5 {
        left: 41.6667%;
    }
    .col-xlg-push-4 {
        left: 33.3333%;
    }
    .col-xlg-push-3 {
        left: 25%;
    }
    .col-xlg-push-2 {
        left: 16.6667%;
    }
    .col-xlg-push-1 {
        left: 8.33333%;
    }
    .col-xlg-push-0 {
        left: 0;
    }
    .col-xlg-offset-12 {
        margin-left: 100%;
    }
    .col-xlg-offset-11 {
        margin-left: 91.6667%;
    }
    .col-xlg-offset-10 {
        margin-left: 83.3333%;
    }
    .col-xlg-offset-9 {
        margin-left: 75%;
    }
    .col-xlg-offset-8 {
        margin-left: 66.6667%;
    }
    .col-xlg-offset-7 {
        margin-left: 58.3333%;
    }
    .col-xlg-offset-6 {
        margin-left: 50%;
    }
    .col-xlg-offset-5 {
        margin-left: 41.6667%;
    }
    .col-xlg-offset-4 {
        margin-left: 33.3333%;
    }
    .col-xlg-offset-3 {
        margin-left: 25%;
    }
    .col-xlg-offset-2 {
        margin-left: 16.6667%;
    }
    .col-xlg-offset-1 {
        margin-left: 8.33333%;
    }
    .col-xlg-offset-0 {
        margin-left: 0;
    }
}
/* Landscape view of all tablet devices */
@media (max-width: 991px) {
    .sm-b-r,
    .sm-b-l,
    .sm-b-t,
    .sm-b-b {
        border-width: 0;
    }
    .sm-b-r {
        border-right-width: 1px;
    }
    .sm-b-l {
        border-left-width: 1px;
    }
    .sm-b-t {
        border-top-width: 1px;
    }
    .sm-b-b {
        border-bottom-width: 1px;
    }
}

/* Landscape view of all tablet devices */
@media (min-width: 1200px) {
    .row-same-height {
        overflow: hidden;
    }
    .row-same-height > [class*="col-lg"] {
        margin-bottom: -99999px;
        padding-bottom: 99999px;
    }
}
@media (min-width: 992px) {
    .row-same-height {
        overflow: hidden;
    }
    .row-same-height > [class*="col-md"] {
        margin-bottom: -99999px;
        padding-bottom: 99999px;
    }
}
@media (min-width: 768px) {
    .row-same-height {
        overflow: hidden;
    }
    .row-same-height > [class*="col-sm"] {
        margin-bottom: -99999px;
        padding-bottom: 99999px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .md-m-t-15 {
        margin-top: 15px !important;
    }

    .md-m-t-20 {
        margin-top: 20px !important;
    }
}

/* Portrait view of all tablet devices */
@media (max-width: 767px) {
    .sm-relative {
        position: relative !important;
    }
    .sm-block {
        display: block !important;
    }
    .sm-pull-bottom,
    .sm-pull-up {
        position: relative !important;
    }
    .sm-p-t-0 {
        padding-top: 0px !important;
    }
    .sm-p-r-0 {
        padding-right: 0px !important;
    }
    .sm-p-l-0 {
        padding-left: 0px !important;
    }
    .sm-p-b-0 {
        padding-bottom: 0px !important;
    }
    .sm-padding-0 {
        padding: 0px !important;
    }
    .sm-p-t-5 {
        padding-top: 5px !important;
    }
    .sm-p-r-5 {
        padding-right: 5px !important;
    }
    .sm-p-l-5 {
        padding-left: 5px !important;
    }
    .sm-p-b-5 {
        padding-bottom: 5px !important;
    }
    .sm-padding-5 {
        padding: 5px !important;
    }
    .sm-p-t-10 {
        padding-top: 10px !important;
    }
    .sm-p-r-10 {
        padding-right: 10px !important;
    }
    .sm-p-l-10 {
        padding-left: 10px !important;
    }
    .sm-p-b-10 {
        padding-bottom: 10px !important;
    }
    .sm-padding-10 {
        padding: 10px !important;
    }
    .sm-p-t-15 {
        padding-top: 15px !important;
    }
    .sm-p-r-15 {
        padding-right: 15px !important;
    }
    .sm-p-l-15 {
        padding-left: 15px !important;
    }
    .sm-p-b-15 {
        padding-bottom: 15px !important;
    }
    .sm-padding-15 {
        padding: 15px !important;
    }
    .sm-p-t-20 {
        padding-top: 20px !important;
    }
    .sm-p-r-20 {
        padding-right: 20px !important;
    }
    .sm-p-l-20 {
        padding-left: 20px !important;
    }
    .sm-p-b-20 {
        padding-bottom: 20px !important;
    }
    .sm-padding-20 {
        padding: 20px !important;
    }
    .sm-p-t-25 {
        padding-top: 25px !important;
    }
    .sm-p-r-25 {
        padding-right: 25px !important;
    }
    .sm-p-l-25 {
        padding-left: 25px !important;
    }
    .sm-p-b-25 {
        padding-bottom: 25px !important;
    }
    .sm-padding-25 {
        padding: 25px !important;
    }
    .sm-p-t-30 {
        padding-top: 30px !important;
    }
    .sm-p-r-30 {
        padding-right: 30px !important;
    }
    .sm-p-l-30 {
        padding-left: 30px !important;
    }
    .sm-p-b-30 {
        padding-bottom: 30px !important;
    }
    .sm-padding-30 {
        padding: 30px !important;
    }
    .sm-p-t-35 {
        padding-top: 35px !important;
    }
    .sm-p-r-35 {
        padding-right: 35px !important;
    }
    .sm-p-l-35 {
        padding-left: 35px !important;
    }
    .sm-p-b-35 {
        padding-bottom: 35px !important;
    }
    .sm-padding-35 {
        padding: 35px !important;
    }
    .sm-p-t-40 {
        padding-top: 40px !important;
    }
    .sm-p-r-40 {
        padding-right: 40px !important;
    }
    .sm-p-l-40 {
        padding-left: 40px !important;
    }
    .sm-p-b-40 {
        padding-bottom: 40px !important;
    }
    .sm-padding-40 {
        padding: 40px !important;
    }
    .sm-p-t-45 {
        padding-top: 45px !important;
    }
    .sm-p-r-45 {
        padding-right: 45px !important;
    }
    .sm-p-l-45 {
        padding-left: 45px !important;
    }
    .sm-p-b-45 {
        padding-bottom: 45px !important;
    }
    .sm-padding-45 {
        padding: 45px !important;
    }
    .sm-p-t-50 {
        padding-top: 50px !important;
    }
    .sm-p-r-50 {
        padding-right: 50px !important;
    }
    .sm-p-l-50 {
        padding-left: 50px !important;
    }
    .sm-p-b-50 {
        padding-bottom: 50px !important;
    }
    .sm-padding-50 {
        padding: 50px !important;
    }
    .sm-m-r-0 {
        margin-right: 0 !important;
    }
    .sm-m-l-0 {
        margin-left: 0 !important;
    }
    .sm-m-b-0 {
        margin-bottom: 0 !important;
    }
    .sm-m-t-0 {
        margin-top: 0 !important;
    }
    .sm-m-t-5 {
        margin-top: 5px !important;
    }
    .sm-m-r-5 {
        margin-right: 5px !important;
    }
    .sm-m-l-5 {
        margin-left: 5px !important;
    }
    .sm-m-b-5 {
        margin-bottom: 5px !important;
    }
    .sm-m-t-10 {
        margin-top: 10px !important;
    }
    .sm-m-r-10 {
        margin-right: 10px !important;
    }
    .sm-m-l-10 {
        margin-left: 10px !important;
    }
    .sm-m-b-10 {
        margin-bottom: 10px !important;
    }
    .sm-m-t-15 {
        margin-top: 15px !important;
    }
    .sm-m-r-15 {
        margin-right: 15px !important;
    }
    .sm-m-l-15 {
        margin-left: 15px !important;
    }
    .sm-m-b-15 {
        margin-bottom: 15px !important;
    }
    .sm-m-t-20 {
        margin-top: 20px !important;
    }
    .sm-m-r-20 {
        margin-right: 20px !important;
    }
    .sm-m-l-20 {
        margin-left: 20px !important;
    }
    .sm-m-b-20 {
        margin-bottom: 20px !important;
    }
    .sm-m-t-25 {
        margin-top: 25px !important;
    }
    .sm-m-r-25 {
        margin-right: 25px !important;
    }
    .sm-m-l-25 {
        margin-left: 25px !important;
    }
    .sm-m-b-25 {
        margin-bottom: 25px !important;
    }
    .sm-m-t-30 {
        margin-top: 30px !important;
    }
    .sm-m-r-30 {
        margin-right: 30px !important;
    }
    .sm-m-l-30 {
        margin-left: 30px !important;
    }
    .sm-m-b-30 {
        margin-bottom: 30px !important;
    }
    .sm-m-t-35 {
        margin-top: 35px !important;
    }
    .sm-m-r-35 {
        margin-right: 35px !important;
    }
    .sm-m-l-35 {
        margin-left: 35px !important;
    }
    .sm-m-b-35 {
        margin-bottom: 35px !important;
    }
    .sm-m-t-40 {
        margin-top: 40px !important;
    }
    .sm-m-r-40 {
        margin-right: 40px !important;
    }
    .sm-m-l-40 {
        margin-left: 40px !important;
    }
    .sm-m-b-40 {
        margin-bottom: 40px !important;
    }
    .sm-m-t-45 {
        margin-top: 45px !important;
    }
    .sm-m-r-45 {
        margin-right: 45px !important;
    }
    .sm-m-l-45 {
        margin-left: 45px !important;
    }
    .sm-m-b-45 {
        margin-bottom: 45px !important;
    }
    .sm-m-t-50 {
        margin-top: 50px !important;
    }
    .sm-m-r-50 {
        margin-right: 50px !important;
    }
    .sm-m-l-50 {
        margin-left: 50px !important;
    }
    .sm-m-b-50 {
        margin-bottom: 50px !important;
    }
    .sm-no-margin {
        margin: 0px;
    }
    .sm-no-padding {
        padding: 0px;
    }
    .sm-text-right {
        text-align: right !important;
    }
    .sm-text-left {
        text-align: left !important;
    }
    .sm-text-center {
        text-align: center !important;
    }
    .sm-pull-right {
        float: right !important;
    }
    .sm-pull-left {
        float: left !important;
    }
    .sm-pull-reset {
        float: none !important;
    }
    .sm-block {
        display: block;
    }
    .sm-image-responsive-height {
        width: 100%;
        height: auto;
    }
}
