.panel-author {
    .author-image {
        width: 80px;
        height: 80px;
    }

    @media (max-width: 766px) {
        .author-image {
            width: 80px;
            min-height: 80px;
        }
    }
}

.page-cover {
    position: relative;
    background: center/cover no-repeat;

    > div {
        z-index: 10;
        position: relative;

        h1, h2, h3, h4, a, .breadcrumb {
            color: #fff;
        }

        .breadcrumb {
           > li + li {
                &:before {
                    color: #ffffff;
                }
            }
        }
    }

    &:after {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        content: '';
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        background: rgba(0, 0, 0, .25);
    }
}

.logo-white {
    display: none;
}

.homepage {
    .navbar-menu:not(.bg-menu) {
        box-shadow: none;
        background: transparent;

        .logo-white {
            display: block;
        }

        .logo-dark {
            display: none;
        }

        .navbar-toggle .icon-bar {
            background-color: @white;
        }

        @media (min-width: 768px) {
            .nav {
                > li {
                    > a:not(.btn) {
                        color: @white;
                    }
                }
            }
        }
    }

    .home-search-bar {
        font-size: 14px;
        padding-top: 88px;

        .form-control {
            &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                color: #686868;
            }

            &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color: #686868;
            }

            &::-moz-placeholder { /* Mozilla Firefox 19+ */
                color: #686868;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #686868;
            }
        }

        h1 {
            font-size: 47px;
            font-weight: 800;
            margin-top: 190px;
        }

        h2 {
            font-size: 35px;
            margin-bottom: 40px;
        }

        @media (max-width: 767px) {
            padding-top: 38px;

            h1 {
                font-size: 39px;
                margin-top: 20px;
            }

            h2 {
                font-size: 25px;
                margin-bottom: 18px;
            }
        }

        &.search-bar-small {
            h1 {
                margin-top: 50px;
                margin-bottom: 40px;
            }

            .search-bar {
                > div {
                    margin-bottom: 40px;
                }
            }
        }

        .search-bar {
            > div {
                margin-bottom: 170px;

                form {
                    > div {
                        > div {
                            input.input-lg {
                                height: 51px;
                                font-size: 16px;
                                padding: 7px 0 7px 22px;
                            }

                            .input-group-addon {
                                i {
                                    font-size: 16px;
                                }
                            }

                            &:first-of-type {
                                margin-right: -1px;

                                input {
                                    border-radius: 4px 0 0 4px;
                                    border-right: 1px solid #CECECE;
                                }

                            }

                            .selectize-control {
                                font-size: 16px;

                                .selectize-input {
                                    border: 0;
                                    height: 51px;
                                    border-radius: 0;
                                    padding: 15px 12px;
                                    border-left: 1px solid #CECECE;
                                    color: #686868;

                                    &.focus {
                                        box-shadow: none;
                                    }
                                }

                                .selectize-dropdown {
                                    border: 8px solid rgba(0, 0, 0, 0) !important;
                                }
                            }

                            @media (max-width: 767px) {
                                .input-group-addon {
                                    border-radius: 0 4px 4px 0;
                                }

                                .selectize-control {
                                    font-size: 16px;

                                    .selectize-input {
                                        border-radius: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            button {
                font-size: 18px;
                padding: 13.6px 16px;
                border-radius: 0 4px 4px 0;
            }

            @media (max-width: 767px) {
                > div {
                    margin-bottom: 50px;
                }

                button {
                    border-radius: 4px;
                }
            }
        }

        //    .nav-tabs {
        //        border: none;
        //        margin-bottom: -1px;
        //
        //        li {
        //            margin-bottom: 0;
        //
        //            a {
        //                border: none;
        //                color: @black;
        //                font-weight: 700;
        //                margin-right: 4px;
        //                padding: 11px 28px;
        //                background-color: @white;
        //                text-transform: uppercase;
        //                border-radius: @border-radius;
        //                border-bottom-left-radius: 0;
        //                border-bottom-right-radius: 0;
        //            }
        //
        //            &.active a {
        //                color: @white;
        //                box-shadow: none;
        //                background-color: @main-bg;
        //
        //                @media (min-width: 768px) and (max-width: 1024px) {
        //                    button {
        //                        font-size: 15px;
        //                        padding: 11px 20px;
        //                    }
        //                }
        //            }
        //        }
        //    }
        //
        //    .tab-content {
        //        z-index: 20;
        //        padding: 12px;
        //        position: relative;
        //        border-radius: @border-radius;
        //        border-top-left-radius: 0;
        //        background-color: @main-bg;
        //
        //        .tab-pane {
        //            padding: 3px 7px;
        //        }
        //
        //        .btn {
        //            height: 47px;
        //            font-size: 21px;
        //        }
        //
        //        .form-group {
        //            margin-bottom: 0;
        //
        //            .form-control {
        //                height: 47px;
        //                font-size: 17px;
        //                box-shadow: 0 0 3px rgba(0,0,0,.2);
        //            }
        //        }
        //    }
        //}
    }
}
//.homepage {
//    //.panel-news {
//    //    .panel-heading {
//    //        padding: 10px 15px;
//    //        padding-top: 15px;
//    //
//    //        .title {
//    //            padding: 0;
//    //        }
//    //
//    //        .news-meta {
//    //            margin: 0;
//    //        }
//    //    }
//    //
//    //    .panel-body {
//    //        padding: 15px;
//    //        padding-top: 0;
//    //    }
//    //
//    //    .panel-footer {
//    //        padding: 10px 15px;
//    //    }
//    //}
//}

//.home-thema-top-tabs {
//    .box-shadow {
//        box-shadow: 0 2px 10px rgba(0,0,0,.2);
//
//        border-radius: @border-radius;
//        border-top-left-radius: 0;
//    }
//
//    .nav {
//        border: none;
//
//        li {
//            font-size: 15px;
//            margin-bottom: 0;
//
//            a {
//                font-weight: normal;
//                margin-bottom: 3px;
//                border-top-right-radius: 0;
//                border-bottom-right-radius: 0;
//            }
//
//            &.active a {
//                z-index: 1;
//                outline: none;
//                font-weight: normal;
//
//                &:after {
//                    top: 3.4455px;
//                    content: "";
//                    width: 38px;
//                    height: 37px;
//                    right: -19px;
//                    /*
//                    top: 4px;
//                    content: "";
//                    width: 35px;
//                    height: 32px;
//                    right: -18px;*/
//                    position: absolute;
//                    background-color: @yellow-bg;
//                    box-shadow: 2px -2px 3px -1px rgba(0,0,0,.2);
//                    transform: rotate(37deg) skew(-19deg);
//                        -moz-transform: rotate(37deg) skew(-19deg);
//                        -ms-transform: rotate(37deg) skew(-19deg);
//                        -o-transform: rotate(37deg) skew(-19deg);
//                        -webkit-transform: rotate(37deg) skew(-19deg);
//                }
//            }
//        }
//    }
//
//    .item-default {
//        padding: 37px 27px 0 37px;
//
//        + .item-default {
//            margin-top: 0;
//        }
//
//        p {
//            line-height: 1.4;
//        }
//
//        .bg-image {
//            height: 100px;
//            margin-bottom: 5px;
//        }
//
//        &[data-nr]:before {
//            z-index: 1;
//            color: @main;
//            padding: 0 11px;
//            font-size: 19px;
//            font-weight: 700;
//            margin-top: -14px;
//            margin-left: -3px;
//            position: absolute;
//            background: @white;
//            border-radius: 50%;
//            content: attr(data-nr);
//            border: 2px solid @main;
//        }
//
//        &[data-nr="10"]:before {
//            padding: 0 6px !important;
//        }
//    }
//}

//.item-default.item-location {
//    padding: 37px;
//
//    padding-top: 0;
//    padding-bottom: 0;
//
//    @media(max-width: 767px) {
//        &.item-lastminutes {
//            padding-left: 15px;
//            padding-right: 15px;
//        }
//    }
//
//    .text-black, .h3 {
//        font-weight: bold;
//    }
//
//    .bg-image {
//        height: 150px;
//        margin-bottom: 15px;
//    }
//
//    .price {
//        font-size: 24px;
//        margin-top: 10px;
//        font-weight: bold;
//
//        small {
//            font-size: 12px;
//        }
//    }
//
//    &:before {
//        z-index: 1;
//        color: @white;
//        padding: 0 11px;
//        font-size: 22px;
//        font-weight: 900;
//        margin-top: -10px;
//        margin-left: -3px;
//        position: absolute;
//        background: @main;
//        border-radius: 50%;
//        content: attr(data-nr);
//    }
//
//    &[data-nr="10"]:before {
//        padding: 0 6px !important;
//    }
//}

.containter-search-bar {
    h1 {
        font-size: 31px;
        margin-top: 57px;
        margin-bottom: 35px;
    }

    .search-bar {
        > div {
            margin-bottom: 60px;

            text-transform: uppercase;

            .form-control {
                text-transform: uppercase;
                &:focus {
                    border: 1px solid #dadbdc;
                    border-right: 0;

                }

                &::-webkit-input-placeholder,
                &:-moz-placeholder,
                &::-moz-placeholder,
                &:-ms-input-placeholder{ /* WebKit, Blink, Edge */
                    text-transform: uppercase;
                }
            }
        }
    }
}

//.searchpage {
//    .panel.panel-card h4.panel-title {
//        font-weight: normal;
//    }
//
//    .checkbox, .checksmall {
//        color: @grey;
//        font-size: 14px;
//
//        label {
//            color: @grey;
//            font-size: 14px;
//        }
//    }
//
//    .checkleft {
//        float: left;
//        margin-right: 5px;
//    }
//}
