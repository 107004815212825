@media (min-width: @screen-desktop) {
    .desktop-height {
        height: 93px;
    }
}

@media(max-width: 1200px) and (min-width: 768px) {
    .navbar.navbar-menu .navbar-nav > li > a {
        //font-size: 10px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media(max-width: 991px) and (min-width: 768px) {
    .navbar.navbar-menu .container {
        width: 100%;
        font-size: 11px;
        padding-left: 8px;
        padding-right: 8px;
    }
}

//.row {
//    > [class^="col"] {
//        padding-left: 10px;
//        padding-right: 10px;
//    }
//}

@media (max-width: 767px) {
    .navbar.navbar-top {
        display: none;
    }

    .navbar.navbar-menu .navbar-nav>li>a.btn-menu-search {
        margin: 0 0 -24px 0 !important;
    }

    .navbar.navbar-menu .navbar-nav > li > a {
        //font-size: 10px;
        padding-left: 2.5px;
        padding-right: 2.5px;
    }
}

@media (max-width: @screen-xs-max) {
    .row {
        > [class^="col"] {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .h5, h5 {
        font-size: 20px;
    }

    .bg-image {
        min-height: 150px;
    }


    //.navbar.navbar-top {
    //    .navbar-toggle {
    //        border-color: @white;
    //
    //        > span {
    //            background-color: @white;
    //        }
    //    }
    //
    //    .navbar-nav:not(.pull-left) {
    //        margin: 0;
    //        float: left;
    //        text-align: left;
    //
    //        > li {
    //            > a {
    //                margin-left: 7px;
    //            }
    //
    //            &:last-of-type {
    //                margin-bottom: 10px;
    //            }
    //        }
    //    }
    //}

    .navbar-menu {
        .nav {
            > li {
                .btn {
                    margin-left: 12px;
                    display: inline-block;
                }
            }
        }
    }
    /* Custom */

    //.home-thema-top-tabs .item-default {
    //    padding: 25px;
    //}
    //
    //.carousel-styled {
    //    .carousel-bg-images {
    //        .carousel-caption {
    //            left: 5%;
    //            right: 5%;
    //
    //            .title {
    //                font-size: 24px;
    //                line-height: 1.6;
    //            }
    //
    //            .btn {
    //                margin-top: 30px;
    //            }
    //        }
    //    }
    //}


    .btn {
        font-size: 16px !important;
    }
}
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
}
@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {

}
@media (min-width: @screen-lg-min) {

}
