//.footer-blocks {
//    background-color: @white;
//
//    > div:not(:last-child) {
//        margin-bottom: 4px;
//    }
//    .footer-block {
//        padding: 0 2px;
//
//        &.foot-text {
//            border-color: @white;
//            border-left-width: 3px;
//            border-right-width: 3px;
//            border-top: 0;
//            border-bottom: 0;
//            border-style: solid;
//            background-color: @green-bg;
//        }
//
//        .footer-img {
//            width: 98.5%;//fluid -> 98.9%
//            height: 100%;
//            position: absolute;
//            background: @white center / cover no-repeat;
//        }
//
//        .footer-text {
//            color: @white;
//            font-size: 18px;
//            line-height: 1.3;
//            padding: 11px 20px;
//            padding-top: 29px;
//            text-align: center;
//
//            p {
//                margin: 0;
//            }
//
//            img {
//                max-width: 150px;
//            }
//
//            .btn {
//                margin-top: 30px;
//                border-radius: 0;
//                padding: 6px 36px;
//            }
//
//            img + .btn {
//                margin-top: 10px;
//            }
//        }
//    }
//
//    @media (max-width: 767px) {
//        .row-eq-height {
//            display: block;
//            margin-bottom: 0 !important;
//
//            .footer-block {
//                margin-bottom: 4px;
//
//                &:not(.foot-text) {
//                    min-height: 200px;
//                }
//            }
//        }
//    }
//}

footer {
    color: @white;
    background-color: @main-bg;

    > .container {
        padding-top: 20px;
        padding-bottom: 20px;

        > .row {
            > div:first-of-type {
                padding-right: 39px;
            }
        }
    }

    p {
        line-height: 23px;
    }

    .list-title {
        color: @white;
        font-size: 23px;
        margin-top: 30px;
        font-weight: normal;
        margin-bottom: 25px;
    }

    ul {
        li {
            line-height: 27px;
        }
    }

    .footer-bar {
        padding: 15px 0;
        font-size: 13px;
        font-weight: normal;
    }
}

.footer-logo {
    width: 153px;
    margin-bottom: 10px;
    margin-top: -20px;
}

